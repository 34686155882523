// extracted by mini-css-extract-plugin
export var freeBox___8EaKb = "PlasmicFeatureCard-module--freeBox___8EaKb--vN9c8";
export var freeBox__g6D99 = "PlasmicFeatureCard-module--freeBox__g6D99--Z9--c";
export var freeBox__kyHso = "PlasmicFeatureCard-module--freeBox__kyHso--ZDvm7";
export var freeBox__u8CI = "PlasmicFeatureCard-module--freeBox__u8CI--j5NE2";
export var freeBoxlong___8EaKb6PDya = "PlasmicFeatureCard-module--freeBoxlong___8EaKb6PDya--e0t-V";
export var freeBoxlong__g6D996PDya = "PlasmicFeatureCard-module--freeBoxlong__g6D996PDya--ha-kq";
export var freeBoxlong__kyHso6PDya = "PlasmicFeatureCard-module--freeBoxlong__kyHso6PDya--Kvu5d";
export var root = "PlasmicFeatureCard-module--root--Eqihd";
export var rootlong = "PlasmicFeatureCard-module--rootlong--c-VTr";
export var slotTargetDescription = "PlasmicFeatureCard-module--slotTargetDescription--JPlNt";
export var slotTargetDescriptionlong = "PlasmicFeatureCard-module--slotTargetDescriptionlong--74-l5";
export var slotTargetTitle = "PlasmicFeatureCard-module--slotTargetTitle--i6I9w";
export var svg__ioIlr = "PlasmicFeatureCard-module--svg__ioIlr--zEBb0";
export var svg__wops9 = "PlasmicFeatureCard-module--svg__wops9--qWXEI";