// extracted by mini-css-extract-plugin
export var __wab_imgSpacer = "PlasmicTestimonial-module--__wab_img-spacer--3eW1G";
export var avatar = "PlasmicTestimonial-module--avatar--j84fQ";
export var freeBox___7DAlW = "PlasmicTestimonial-module--freeBox___7DAlW--Uq8yG";
export var freeBox__t2XMh = "PlasmicTestimonial-module--freeBox__t2XMh--mUs27";
export var img = "PlasmicTestimonial-module--img--LLYpw";
export var root = "PlasmicTestimonial-module--root--b0trQ";
export var svg = "PlasmicTestimonial-module--svg--HsaxC";
export var text__fkhVh = "PlasmicTestimonial-module--text__fkhVh--Lks69";
export var text__stEz1 = "PlasmicTestimonial-module--text__stEz1--ekT9D";
export var text__ttu4 = "PlasmicTestimonial-module--text__ttu4--hF4Bq";